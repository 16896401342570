<template>
  <v-container
    class="pa-0"
    v-if="useContainer"
  >
    <v-sheet
      :color="backgroundColor"
    >
      <v-row no-gutters class="d-flex align-center">
        <v-col class="pr-5 flex-grow-1">
          <div class="w-100" :style="lineStyles" />
        </v-col>
        <v-col class="flex-grow-0 content">
          <slot>
            <span :style="contentStyles">{{ title }}</span>
          </slot>
        </v-col>
        <v-col class="pl-5 flex-grow-1">
          <div class="w-100" :style="lineStyles" />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>

  <v-sheet
    class="pa-0"
    v-else
    :color="backgroundColor"
  >
    <v-row no-gutters class="d-flex align-center">
      <v-col class="pr-5 flex-grow-1">
        <div class="w-100" :style="lineStyles" />
      </v-col>
      <v-col class="flex-grow-0 content">
        <slot>
          <span :style="contentStyles">{{ title }}</span>
        </slot>
      </v-col>
      <v-col class="pl-5 flex-grow-1">
        <div class="w-100" :style="lineStyles" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    titleSize: {
      type: String,
      default: '20px'
    },
    titleWeight: {
      type: String,
      default: '500'
    },
    titleColor: {
      type: String,
      default: 'rgba(0,0,0,.87)'
    },
    useContainer: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    lineColor: {
      type: String,
      default: '#a5abb7'
    },
    lineThickness: {
      type: String,
      default: '2px'
    },
    lineStyle: {
      type: String,
      validator(value) {
        const allowed = ['dashed', 'dotted', 'double', 'solid'];
        if (!allowed.includes(value)) {
          throw new Error(`Invalid lineStyle '${value}'. Valid options are: ${allowed}`)
        }
        return true;
      },
      default: 'solid'
    },
  },

  computed: {
    lineStyles() {
      return {
        borderBottomStyle: this.lineStyle,
        borderBottomWidth: this.lineThickness,
        borderColor: this.lineColor
      }
    },

    contentStyles() {
      return {
        color: this.titleColor,
        fontSize: this.titleSize,
        fontWeight: this.titleWeight
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.content {
  white-space: nowrap;
  font-size: 20px;
}
</style>
