var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.dealSlides && _vm.dealSlides.length))?_c('div',{},[_c('SliderComp',{attrs:{"items":_vm.dealSlides,"key-prefix":"todays-deal-horizontal-slide","per-page":"5","autoplay":"","interval":"6000","rewind":"","loop":false,"breakpoints":{
      600: { // xs
        perPage: 2
      },
      960: { // sm
        perPage: 3
      },
      1264: { // md
        perPage: 4
      }
    }},scopedSlots:_vm._u([{key:"slide-item",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":item.action_link}},[_c('v-img',{attrs:{"src":item.image,"alt":item.title,"min-height":"140px","min-width":"140px","aspect-ratio":"1","eager":""}})],1)]}}],null,false,2061001671)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }