<template>
  <v-carousel
    v-if="(homeSlides && homeSlides.length)"
    height="auto"
    hide-delimiter-background
    show-arrows-on-hover
    continuous
    cycle
    interval="3500"
  >
    <v-carousel-item v-for="(item, i) in homeSlides" :key="i">
      <router-link :to="item.action_link">
        <v-img :src="item.image" contain />
      </router-link>
    </v-carousel-item>
  </v-carousel>

  <SectionLoadingSpinner v-else-if="showSectionLoader" />
  <v-card-title v-else>No data Available</v-card-title>
</template>

<script>
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';

export default {
  name: 'MainSlider',
  components: { SectionLoadingSpinner },
  props: {
    homeSlides: Array,
    showSectionLoader: Boolean
  },
};
</script>

<style scoped>

</style>
