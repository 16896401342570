<template>
  <SliderComp
    :items="products"
    key-prefix="homePageProducts"
    per-page="6"
    :loop="false"
    rewind
    :breakpoints="productBreakPoints"
  >
    <template #slide-item="{ item }">
      <ProductCard
        :item="item"
      />
    </template>
  </SliderComp>
</template>

<script>
import ProductCard from '@/components/Product/ProductCard';
import SliderComp from '@/components/Utils/SliderComp';

export default {
  name: 'product-slider',
  components: { SliderComp, ProductCard },
  props: {
    products: {
      type: [Array, Object],
    },
    qtyByResolution: {
      type: Object,
      default: function () {
        return {
          600: 1,
          960: 2,
          1264: 3,
          1904: 4
        };
      }
    }
  },

  computed: {
    productBreakPoints () {

      const breakPoints = {};

      Object.keys(this.qtyByResolution).map(resolution => {

        breakPoints[resolution] = {
          perPage: this.qtyByResolution[resolution]
        };

      });

      return breakPoints;
    }
  }
};
</script>
