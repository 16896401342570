<template>
  <v-sheet class="mega-menu-container">
    <v-list class="mega-menu-list" v-if="categories.length">
      <v-list-item
        v-for="(category, index) in categories"
        :key="index"
        class="mega-menu-list-item"
        style="position: relative; min-height: initial"
        color="primary"
      >

        <v-menu
          :close-on-content-click="false"
          :nudge-right="-10"
          :nudge-top="10"
          :nudge-width="420"
          max-width="700px"
          offset-x
          style="right: 0; position: absolute"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              active-class=" "
              link
              style="min-height: initial"
              v-on="on"
              v-bind="attrs"
            >
              <v-list-item-content
                class="text-uppercase pa-0 ma-0 menu-item-title"
              >
                {{ category.name }}
              </v-list-item-content>
              <v-list-item-icon style="margin: 7px 0">
                <v-icon> mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>

          <v-card class="menu-container">
            <v-card-text>
              <v-container>
                <v-row>

                  <v-col
                    v-for="(subCategory, index) in category.sub_categories"
                    :key="'sub-category'+index"
                    :cols="4"
                  >
                    <v-list>
                      <v-list-item-group>
                        <v-list-item-title
                          class="text-uppercase cursor-pointer brand-item"
                          @click="navigateToProduct(category.id, subCategory.id)"
                        >
                          {{ subCategory.name }}
                          <v-divider></v-divider>
                        </v-list-item-title>
                        <div class="sub-menu-item-lists">
                        <v-list-item
                          v-for="(brand, index) in subCategory.brands"
                          :key="'brand'+index"
                          class="pa-0"
                          link
                          style="min-height: initial"
                          :to="{name: routeMap.products.name, query: {brand: brand.slug}}"
                        >
                          <v-list-item-content class="pb-2 pt-2 ma-0">
                            <v-list-item-action-text style="font-size: .9rem; line-height: 1.2"> {{ brand.name }}
                            </v-list-item-action-text>
                          </v-list-item-content>
                        </v-list-item>
                        </div>
                      </v-list-item-group>
                    </v-list>
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-list-item>
    </v-list>

    <SectionLoadingSpinner v-else-if="showSectionLoader" />
    <v-card-title v-else>No data Available</v-card-title>

  </v-sheet>
</template>

<script>
import SectionLoadingSpinner from '@/components/Utils/SectionLoadingSpinner';
import routeMap from '@/utils/routeMap';

export default {
  name: 'LeftMegaMenu',
  components: { SectionLoadingSpinner },
  props: {
    categories: Array,
    showSectionLoader: Boolean,
  },
  data() {
    return {
      routeMap
    };
  },
  methods: {
    navigateToProduct( category, subCategory ) {
      const urlToGo = {name: routeMap.products.name, query: {category: category, sub : subCategory}}
      return this.$router.push(urlToGo);
    }
  }
};
</script>

<style scoped lang="scss">
.sub-menu-item-lists{
  max-height: 160px;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 10px 0;
}

.menu-item-title {
  color: var(--v-anchor-base);
}

.mega-menu-list-item {
  &:hover, &:active {
    .menu-item-title {
      color: #ffffff;
    }
  }
}

.v-list-item--link:before {
  color: var(--v-anchor-base);
}

.mega-menu-list-item:hover {
  background: var(--v-anchor-base);
}
.mega-menu-list-item:active {
  background: var(--v-anchor-base);
}

.brand-item {
  color: var(--v-anchor-base);
}


.menu-container{
  max-height: 450px;
  overflow: scroll;
  overflow-x: hidden;
  display: block;
}
</style>
