<template>
  <Splide
    :options="slideOptions"
  >
    <SplideSlide
      v-for="(item, index) in items"
      :key="`${keyPrefix}${index}`"
    >
      <slot
        name="slide-item"
        :item="item"
        :itemIndex="index"
      >
        Please use slide item slot
      </slot>
    </SplideSlide>
  </Splide>
</template>

<script>
export default {
  name: 'SliderComp',
  props: {
    items: {
      type: Array,
      required: true
    },
    keyPrefix: {
      type: String,
      required: true
    },
    loop: {
      type: Boolean,
      default: true
    },
    interval: {
      type: [Number, String],
      default: 5000
    },
    rewind: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    pauseOnHover: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    autoHeight: {
      type: Boolean,
      default: false
    },
    arrowOnOutside: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: [Number, String],
      default: 1
    },
    perMove: {
      type: [Number, String],
      default: 1
    },
    itemGap: {
      type: String,
      default: '10px'
    },
    breakpoints: {
      type: Object,
      default: () => ({
        640: {
          perPage: 1,
        },
        480: {
          perPage: 1,
        },
      })
    }
  },

  data: () => ({}),

  computed: {
    slideOptions() {
      return {
        autoWidth: this.autoWidth,
        autoHeight: this.autoHeight,
        type: this.loop ? 'loop' : 'slide',
        rewind: this.rewind,
        perPage: parseInt(this.perPage),
        perMove: parseInt(this.perMove),
        gap: this.itemGap,
        autoplay: this.autoplay,
        interval: parseInt(this.interval),
        pagination: this.pagination,
        pauseOnHover: this.pauseOnHover,
        breakpoints: this.breakpoints,
        classes: this.arrowClasses,
      };
    },

    arrowClasses() {
      return {
        prev: `splide__arrow--prev ${this.arrowOnOutside ? 'slider-comp-arrow-out-prev' : ''}`,
        next: `splide__arrow--next ${this.arrowOnOutside ? 'slider-comp-arrow-out-next' : ''}`
      }
    }
  },

};
</script>

<style lang="scss">
.slider-comp-arrow-out- {
  &prev {
    margin-left: -55px;
  }
  &next {
    margin-right: -55px;
  }
}
</style>
